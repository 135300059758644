import { useBackendApi } from '@/api';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useDialog } from '@/hooks/local/useDialog';
import { usePopper } from '@/hooks/local/usePopper';
import { useCallback, useState } from 'react';

export const useDownloadButtonHandlers = () => {
  const { featureData } = useFeaturesContext({});

  const { findActiveStep, updateFeatureFromTaskApiResponse } =
    useFeaturesContext({});
  const { handleClosePopper } = usePopper();
  const { getTask } = useBackendApi({});
  const {
    isOpenDialog: isOpenConfirmDialog,
    setIsOpenDialog: setIsOpenConfirmDialog,
  } = useDialog();
  const [isOpenDownloadDialog, setIsOpenDownloadDialog] = useState(false);
  const [taskId, setTaskId] = useState<string>('');
  const [taskType, setTaskType] = useState<string>('');

  const handleSubmitTargetDownloadStep = useCallback(
    (id: string) => {
      handleClosePopper();
      void (async () => {
        try {
          const res = await getTask(id, {});
          if (res) {
            void updateFeatureFromTaskApiResponse(res);
          }
        } catch (error) {
          console.error(error);
        }
      })();
      setIsOpenConfirmDialog(false);
    },
    [
      getTask,
      handleClosePopper,
      setIsOpenConfirmDialog,
      updateFeatureFromTaskApiResponse,
    ],
  );

  const handleSwitchTargetDownloadStep = useCallback(
    (id: string) => {
      const activeStep = findActiveStep();
      if (
        activeStep &&
        activeStep.name !== 'upload' &&
        (activeStep.name !== 'batchUpload' ||
          // batchUploadでかつ画像アップロード済みの場合
          (activeStep.name === 'batchUpload' &&
            featureData !== undefined &&
            featureData.batch.length >= 1 &&
            featureData.batch[0].originalImages.length >= 1))
      ) {
        setIsOpenConfirmDialog(true);

        return;
      }
      handleSubmitTargetDownloadStep(id);
    },
    [
      findActiveStep,
      handleSubmitTargetDownloadStep,
      setIsOpenConfirmDialog,
      featureData,
    ],
  );

  const handleCloseConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(false);
  }, [setIsOpenConfirmDialog]);

  const handleOpenDownloadDialog = useCallback((id: string, type: string) => {
    setIsOpenDownloadDialog(true);
    setTaskId(id);
    setTaskType(type);
  }, []);

  const handleCloseDownloadDialog = useCallback(() => {
    setIsOpenDownloadDialog(false);
    setTaskId('');
  }, []);

  return {
    handleSwitchTargetDownloadStep,
    handleSubmitTargetDownloadStep,
    handleCloseConfirmDialog,
    handleOpenDownloadDialog,
    handleCloseDownloadDialog,
    isOpenConfirmDialog,
    isOpenDownloadDialog,
    taskId,
    taskType,
    setTaskId,
  };
};
