import { useBatchUpload } from '@/features/components/steps/BatchUpload/hooks/useBatchUpload';
import { WarningFunction } from '@/features/components/steps/BatchUpload/hooks/useBatchUploadWarnings';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { MdAdd } from 'react-icons/md';
import { StyledAddButtonBox, StyledButtonContent } from './styled';

type Props = {
  targetId: string;
  targetNumber: number;
  disabled: boolean;
  warningFunction: WarningFunction;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  isDragActive: boolean
};

export const UploadAddInterface = ({
  targetId,
  targetNumber,
  disabled,
  warningFunction,
  getRootProps,
  getInputProps,
  isDragActive,
}: Props): JSX.Element => {
  const { featureData, handleChangeCurrentSet } =
    useBatchUpload();

  return (
    <>
      <StyledButtonContent
        {...(disabled ? () => {} : getRootProps())}
        isDragActive={isDragActive}
      >
        <input
          {...(disabled
            ? { type: 'hidden' }
            : getInputProps({
                className: `upload-add-interface${featureData?.batch[targetNumber].id}`,
              }))}
        />
        <StyledAddButtonBox
          onClick={() => {
            if (disabled) {
              handleChangeCurrentSet(targetId);
            }
            warningFunction.resetWarnings();
          }}
        >
          <MdAdd
            size={40}
            color={
              disabled
                ? 'var(--color-primary-soft)'
                : 'var(--color-primary-main)'
            }
          />
        </StyledAddButtonBox>
      </StyledButtonContent>
    </>
  );
};
