import { Box } from '@/components/atoms/Box';
import {
  StyledFlexCenter,
  StyledFlexGap8,
  StyledGridEnd,
  StyledGridGap25,
} from '@/components/styled';
import {
  StyledFunctionClearButton,
  StyledFunctionNextButton,
  StyledFunctionPreviewButton,
} from '@/components/styled/button';
import { memo } from 'react';

type Props = {
  onSubmit: () => void;
  samType: 'manual' | 'auto';
  isPreviewLoading: boolean;
  isPreviewCompleted: boolean;
  positivePoints: [number, number][];
  negativePoints: [number, number][];
  handleRequestPreview: (samType: 'manual' | 'auto') => Promise<void>;
  initializeSamDialogState: () => void;
};

/**
 * JSDoc
 * @see セグメンテーションのボタンコンポーネント
 * @see manualの場合のみプレビューとクリアボタンを表示
 * @param {Props} { field }
 * @returns {JSX.Element}
 */
export const Buttons = memo(
  ({
    onSubmit,
    samType,
    isPreviewLoading,
    isPreviewCompleted,
    positivePoints,
    negativePoints,
    handleRequestPreview,
    initializeSamDialogState,
  }: Props): JSX.Element => {
    const handleClick = () => {
      switch (samType) {
        case 'manual':
          void handleRequestPreview('manual');
          break;
        default:
          break;
      }
    }

    return (
      <>
        <StyledGridEnd>
          <StyledFlexCenter>
            <StyledGridGap25>
              <>
                {samType === 'manual' ? (
                  <>
                    <Box style={{ height: 30 }} />
                    <StyledFlexGap8>
                      <StyledFunctionPreviewButton
                        onClick={handleClick}
                        disabled={
                          isPreviewLoading ||
                          positivePoints.length === 0
                        }
                      >
                        プレビュー
                      </StyledFunctionPreviewButton>
                      <StyledFunctionClearButton
                        onClick={initializeSamDialogState}
                        disabled={
                          !(
                            positivePoints.length ||
                            negativePoints.length
                          )
                        }
                      >
                        クリア
                      </StyledFunctionClearButton>
                    </StyledFlexGap8>
                  </>
                ) : (
                  <>
                    <Box style={{ height: 214 }} />
                  </>
                )}
                <StyledFunctionNextButton
                  disabled={!isPreviewCompleted || isPreviewLoading}
                  onClick={onSubmit}
                >
                  この領域で変更
                </StyledFunctionNextButton>
              </>
              <Box />
            </StyledGridGap25>
          </StyledFlexCenter>
        </StyledGridEnd>
      </>
    );
  },
);
