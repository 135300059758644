import { Box } from '@/components/atoms/Box';
import { FadeIn } from '@/components/molecules/FadeIn';
import { StyledFlexCenterExpanded } from '@/components/styled';
import {
  TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
  TASK_TYPE_TO_JP_MODE_FROM_BACKEND,
} from '@/constants';
import { Skelton } from '@/features/components/atoms/Skelton';
import { DownloadDialog } from '@/features/components/modals/DownloadDialog';
import { useGetTaskApi } from '@/features/components/modals/DownloadDialog/hooks/useGetTaskApi';
import { GlobalNavigator } from '@/features/components/organisms/GlobalNavigator';
import { StyledScrollContainer } from '@/features/components/styled/navigator';
import { StyledTable } from '@/features/components/styled/table';
import { FeaturesSingleLayoutWithScroll } from '@/features/components/templates/Layout/FeaturesSingleLayoutWithScroll';
import { useDownloadButtonHandlers } from '@/features/Gallery/hooks/useDownloadButtonHandlers';
import { usePopper } from '@/hooks/local/usePopper';
import { useTasks, useTasksHandlers, useTasksParam } from '@/hooks/tasks';
import { getModeEn, getModeJp } from '@/utils/task';
import { Table } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { Body } from './Body';
import { Head } from './Head';
import { Search } from './Search';

export const TaskList = memo((): JSX.Element => {
  const {
    tasksParam,
    updateTasksParamObject,
    addUserId,
    removeUserId,
    tasksOrderBy,
    handleClickOrderBy,
  } = useTasksParam({});

  const { isToggledUserId, handelChangeLimit, handleToggleUserId } =
    useTasksHandlers({
      updateTasksParamObject,
      addUserId,
      removeUserId,
    });

  const {
    apiResponse,
    isLoading,
    count,
    page,
    scrollBoxRef,
    handleChangePage,
    isAutoReload,
  } = useTasks({
    tasksParam,
    updateTasksParamObject,
    hasAutoReload: true,
  });

  const {
    handleSubmitTargetDownloadStep,
    handleOpenDownloadDialog,
    handleCloseDownloadDialog,
    isOpenDownloadDialog,
    taskId,
    taskType,
  } = useDownloadButtonHandlers();

  const { handleClosePopper } = usePopper();
  const { setTaskApiResponse } = useGetTaskApi({ taskId });
  // TODO: 元々DownloadDialogで共通の処理となっていたが、ギャラリーのDownloadDialogとBatchDownloadのDownloadDialogでは処理を分ける必要があったため、ここで定義している。（BatchDownloadでは `setTaskApiResponse(undefined)` を実行するとバグる）
  // TODO: ここでなぜhandleClosePopperを必要としているのか不明（なくても閉じている？）。要調査。
  const handleCloseDialogWithPopper = useCallback(() => {
    setTaskApiResponse(undefined);
    handleCloseDownloadDialog();
    handleClosePopper();
  }, [handleCloseDownloadDialog, handleClosePopper, setTaskApiResponse]);

  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <FeaturesSingleLayoutWithScroll>
        <Search
          updateTasksParamObject={updateTasksParamObject}
          isToggledUserId={isToggledUserId}
          handleToggleUserId={handleToggleUserId}
        />
        <StyledScrollContainer ref={scrollBoxRef}>
          {!isAutoReload && isLoading && (
            <Box>
              <Skelton mode="taskList" />
            </Box>
          )}
          {(isAutoReload || !isLoading) && apiResponse && (
            <FadeIn duration={0.2}>
              {apiResponse.data.length <= 0 ? (
                <StyledFlexCenterExpanded>
                  データがありません
                </StyledFlexCenterExpanded>
              ) : (
                <>
                  <StyledTable>
                    <Table>
                      <Head
                        tasksOrderBy={tasksOrderBy}
                        handleClickOrderBy={handleClickOrderBy}
                      />
                      <Body
                        apiResponse={apiResponse}
                        handleSubmitTargetDownloadStep={
                          handleSubmitTargetDownloadStep
                        }
                        handleOpenDownloadDialog={handleOpenDownloadDialog}
                        selectedColumnIndex={selectedColumnIndex}
                        setSelectedColumnIndex={setSelectedColumnIndex}
                      />
                    </Table>
                  </StyledTable>
                  <DownloadDialog
                    taskId={taskId}
                    isOpenDownloadDialog={isOpenDownloadDialog}
                    handleCloseDownloadDialog={() => {
                      handleCloseDialogWithPopper()
                      setSelectedColumnIndex(undefined)
                    }} // BatchDownloadと違って色々やる（定義参照）
                    targetModeJp={getModeJp(
                      taskType as keyof typeof TASK_TYPE_TO_JP_MODE_FROM_BACKEND,
                    )}
                    targetModeEn={getModeEn(
                      taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
                    )}
                  />
                </>
              )}
            </FadeIn>
          )}
        </StyledScrollContainer>
        <GlobalNavigator
          isLoading={isLoading}
          limit={tasksParam.limit}
          count={count}
          page={page}
          handelChangeLimit={handelChangeLimit}
          handleChangePage={handleChangePage}
        />
      </FeaturesSingleLayoutWithScroll>
    </>
  );
});
