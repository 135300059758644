import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { StyledBoxPaddingTop100, StyledFlex } from '@/components/styled';
import { GlobalDownloader } from '@/features/components/organisms/GlobalDownloader';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useDownload } from '@/hooks/global/useDownload';
import { Divider } from '@mui/material';
import { memo } from 'react';
import { SharedDownloadButton } from '../SharedDownloadButton';
import { SharedHeaderContent } from '../SharedHeaderContent';
import { StyledDialogContainer, StyledDialogWidthBox } from '../styled';

type Props = {
  taskApiResponse: Task;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
};

export const SingleDownloadContent = memo(
  ({ taskApiResponse, handleOpenPopper, checkedCount }: Props): JSX.Element => {
    console.log('batch download dialog');
    const { setFiles } = useDownload({
      taskApiResponse,
    });

    return (
      <>
        <StyledDialogContainer style={{ width: 940 }}>
          <FeaturesModalDownloadLayout>
            <StyledDialogWidthBox style={{ width: '890px' }}>
              <SharedHeaderContent taskApiResponse={taskApiResponse} />
              <Divider />
            </StyledDialogWidthBox>
            <StyledBoxPaddingTop100>
              <StyledFlex
                style={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '890px',
                  gap: '20px',
                }}
              >
                <GlobalDownloader
                  isGenerated
                  imageResults={taskApiResponse?.result.resultImages || []}
                  setFiles={setFiles}
                  repeatBackgroundImage={
                    taskApiResponse?.parameters?.noBackground
                      ? houndstooth.src
                      : ''
                  }
                  hasCheckIcon
                  taskType={taskApiResponse.taskType}
                />
              </StyledFlex>
            </StyledBoxPaddingTop100>
            <StyledFunctionBox>
              <StyledBoxPaddingTop100>
                <SharedDownloadButton
                  handleOpenPopper={handleOpenPopper}
                  checkedCount={checkedCount}
                />
              </StyledBoxPaddingTop100>
            </StyledFunctionBox>
          </FeaturesModalDownloadLayout>
        </StyledDialogContainer>
      </>
    );
  },
);
