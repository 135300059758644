/* eslint-disable import/newline-after-import */
import type { SVGProps } from 'react';
import { memo } from 'react';
const BigUnChecked = memo((props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      fill="none"
      {...props}
    >
      <rect width={48} height={48} fill="#fff" rx={24} />
      <circle
        cx={24}
        cy={24}
        r={20}
        stroke="#000"
        strokeOpacity={0.12}
        strokeWidth={2}
      />
    </svg>
  );
});
export default BigUnChecked;
