import { Box } from '@/components/atoms/Box';
import { StyledBoxHeight100vh, StyledFlex } from '@/components/styled';
import { ContentHeader } from '@/features/components/organisms/ContentHeader';
import {
  StyledStepLeftCentering
} from '@/features/components/templates/Layout/styled';
import { memo } from 'react';

type Props = {
  children: React.ReactNode[];
};

export const FeaturesBatchStepLayout = memo(
  ({ children }: Props): JSX.Element => {
    const [topNode, leftNode, rightNode] = children;

    return (
      <>
        <ContentHeader />
        <StyledBoxHeight100vh>
          <StyledFlex>
            <Box>
              <Box
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: 'calc(100vw - 400px)',
                  overflow: 'hidden',
                }}
              >
                {topNode}
              </Box>
              <Box
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: 'calc(100vw - 400px)',
                  height: 'calc(100vh - 60px)',
                  overflow: 'hidden',
                }}
              >
                <StyledStepLeftCentering>
                  <Box style={{
                    height: 'calc(100% - 64px)',
                    width: '100%',
                    overflow: 'auto', // TODO: 意味なさそうなのでデザイン変更のタイミングがあれば消す
                  }}>
                    {leftNode}
                  </Box>
                </StyledStepLeftCentering>
              </Box>
            </Box>
            <Box
              style={{
                position: 'relative',
                width: '320px',
                minWidth: '320px',
                maxWidth: '320px',
                height: '100vh',
                borderLeft: `1px solid ${'var(--color-gray-light)'}`,
                backgroundColor: 'var(--color-gray-white)',
                zIndex: 2,
              }}
            >
              <Box style={{
                height: 'calc(100% - 64px)',
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                position: 'relative'
              }}>
                {rightNode}
              </Box>
            </Box>
          </StyledFlex>
        </StyledBoxHeight100vh>
      </>
    );
  },
);
