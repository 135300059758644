import { Box } from '@/components/atoms/Box';
import {
  BackgroundIcon,
  ColorIcon,
  ColorTemperatureIcon,
  GalleryIcon,
  ItemIcon,
  WhiteIcon,
} from '@/components/icons';
import { StyledFlexEnd, StyledGridGap5 } from '@/components/styled';
import { StyledHomeBorderButton } from '@/components/styled/button';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import {
  StyledLargeIconBox,
  StyledLargeIconScale,
  StyledMediumIconBox,
} from '@/features/components/styled/icon';
import { FeaturesSingleLayout } from '@/features/components/templates/Layout/FeaturesSingleLayout';
import { memo, useCallback } from 'react';
import { PiCaretRightLight } from 'react-icons/pi';
import {
  StyledCardBox,
  StyledCardBox90,
  StyledCardButtonFlex,
  StyledCardExplain,
  StyledCardTitle,
  StyledCardUpperFlex,
  StyledDashboardCardBox,
  StyledDashboardContainer,
} from './styled';

export const Dashboard = memo((): JSX.Element => {
  const { activateTargetFeature } = useFeaturesContext({});
  const handleClick = useCallback(
    (feature: string) => {
      activateTargetFeature(feature);
    },
    [activateTargetFeature],
  );

  return (
    <FeaturesSingleLayout>
      <StyledDashboardContainer>
        <StyledDashboardCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <BackgroundIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5>
                <StyledCardTitle>背景画像を変える</StyledCardTitle>
                <StyledCardExplain>
                  画像の背景を単色カラー／一般的な背景に挿げ替える
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('background');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('backgroundLora');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <WhiteIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5>
                <StyledCardTitle>背景を白抜きに変える</StyledCardTitle>
                <StyledCardExplain>
                  画面の背景を白い背景に挿げ替える
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('white');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('multiWhite');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
        </StyledDashboardCardBox>
        <StyledDashboardCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ColorIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5>
                <StyledCardTitle>カラーを変える</StyledCardTitle>
                <StyledCardExplain>
                  選択したアイテムのカラーを変更する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('color');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ColorTemperatureIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5>
                <StyledCardTitle>色温度を調整する</StyledCardTitle>
                <StyledCardExplain>
                  選択したアイテムの色温度を調整する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('colorTemperature');
                }}
              >
                複数枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
        </StyledDashboardCardBox>
        <StyledDashboardCardBox style={{ alignItems: 'flex-start' }}>
          <StyledCardBox>
            <StyledCardUpperFlex>
              <StyledLargeIconBox>
                <StyledLargeIconScale>
                  <ItemIcon color="#424242" />
                </StyledLargeIconScale>
              </StyledLargeIconBox>
              <StyledGridGap5>
                <StyledCardTitle>アイテムを変える</StyledCardTitle>
                <StyledCardExplain>
                  選択したアイテムのデザインを変更する
                </StyledCardExplain>
              </StyledGridGap5>
            </StyledCardUpperFlex>
            <StyledCardButtonFlex>
              <StyledHomeBorderButton
                onClick={() => {
                  handleClick('item');
                }}
              >
                1枚のパターンを生成
              </StyledHomeBorderButton>
            </StyledCardButtonFlex>
          </StyledCardBox>
          <StyledCardBox90
            onClick={() => {
              handleClick('gallery');
            }}
          >
            <StyledCardUpperFlex>
              <Box>
                <StyledMediumIconBox>
                  <GalleryIcon color="#424242" />
                </StyledMediumIconBox>
              </Box>
              <StyledGridGap5>
                <StyledCardTitle>生成ギャラリー</StyledCardTitle>
              </StyledGridGap5>
              <StyledFlexEnd>
                <PiCaretRightLight size={16} />
              </StyledFlexEnd>
            </StyledCardUpperFlex>
          </StyledCardBox90>
        </StyledDashboardCardBox>
      </StyledDashboardContainer>
    </FeaturesSingleLayout>
  );
});
