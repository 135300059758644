import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledFlex, StyledFlexEnd, StyledFont } from '@/components/styled';
import { TaskCellContent } from '@/features/components/atoms/TaskCellContent';
import { TaskCellMode } from '@/features/components/atoms/TaskCellMode';
import { useConvertDate } from '@/hooks/utils/useConvertDate';
import { memo } from 'react';
import { StyledHeaderBox } from '../styled';

type Props = {
  taskApiResponse: Task;
};

export const SharedHeaderContent = memo(
  ({ taskApiResponse }: Props): JSX.Element => {
    const { convertToDateTime } = useConvertDate();

    return (
      <>
        <StyledHeaderBox>
          <Box>
            <TaskCellMode data={taskApiResponse} />
          </Box>
          <Box>
            <TaskCellContent data={taskApiResponse} />
          </Box>
          <Box>
            <StyledFlex style={{ minWidth: 240, gap: '10px' }}>
              <Box>
                <Box style={{ height: 24 }}>
                  {convertToDateTime(taskApiResponse?.updatedAt)}
                </Box>
                <Box>{taskApiResponse.user.email}</Box>
              </Box>
            </StyledFlex>
          </Box>
          <StyledFlexEnd>
            <StyledFont>生成完了</StyledFont>
          </StyledFlexEnd>
        </StyledHeaderBox>
      </>
    );
  },
);
