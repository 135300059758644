import { Task } from '@/api/schemas';
import houndstooth from '@/assets/any/houndstooth.png';
import { Box } from '@/components/atoms/Box';
import {
  StyledBoxPaddingTop100,
  StyledFlex,
  StyledGrid,
} from '@/components/styled';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useParamsContext } from '@/contexts/ParamsContext';
import { ImageSpinner } from '@/features/components/atoms/ImageSpinner';
import { GlobalDownloader } from '@/features/components/organisms/GlobalDownloader';
import { StyledFunctionBox } from '@/features/components/styled/function';
import { FeaturesModalDownloadLayout } from '@/features/components/templates/Layout/FeaturesModalDownloadLayout';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useDownload } from '@/hooks/global/useDownload';
import { Divider } from '@mui/material';
import Image from 'next/image';
import { memo, useCallback } from 'react';
import { ReEditButton } from '../ReEditButton';
import { SharedDownloadButton } from '../SharedDownloadButton';
import { SharedHeaderContent } from '../SharedHeaderContent';
import { StyledDialogContainer, StyledDialogWidthBox } from '../styled';

type Props = {
  taskApiResponse: Task;
  handleOpenPopper: (e: React.MouseEvent<HTMLElement>) => void;
  checkedCount: number;
};

export const ColorTemperatureContent = memo(
  ({ taskApiResponse, handleOpenPopper, checkedCount }: Props): JSX.Element => {
    const { setFiles } = useDownload({
      taskApiResponse,
    });

    const { activateTargetStep } = useFeaturesContext({})
    const { activateTargetFeatureWithParams } = useParamsContext()
    const { checkArray } = useCheckArray();

    const moveToReEditColorTemperature = useCallback(() => {
      if (!taskApiResponse?.id) return

      const indexes: number[] = [];
      checkArray.forEach((isChecked, index) => {
        if (isChecked) indexes.push(index)
      })
      activateTargetStep('reEditContainer');
      activateTargetFeatureWithParams('reEditColorTemperature', {
        id: taskApiResponse.id,
        selectedImageIndexes: indexes,
      })
    },[
      activateTargetStep,
      activateTargetFeatureWithParams,
      taskApiResponse?.id,
      checkArray,
    ]);

    return (
      <>
        <StyledDialogContainer style={{ width: 1200 }}>
          <FeaturesModalDownloadLayout>
            <StyledDialogWidthBox style={{ width: '1150px' }}>
              <SharedHeaderContent taskApiResponse={taskApiResponse} />
              <Divider />
            </StyledDialogWidthBox>
            <StyledBoxPaddingTop100>
              <StyledFlex
                style={{
                  position: 'relative',
                  alignItems: 'flex-start',
                  width: '1100px',
                  gap: '20px',
                }}
              >
                {taskApiResponse.refImage?.inputImage.originalImageUrl && (
                  <StyledGrid style={{ gap: '16px' }}>
                    <Box
                      style={{
                        position: 'relative',
                        width: 240,
                        height: 240,
                        minWidth: 240,
                      }}
                    >
                      <ImageSpinner
                        src={`${taskApiResponse.refImage.inputImage.originalImageUrl}&d=240x240`}
                        hasBackground={false}
                      >
                        <Image
                          src={`${taskApiResponse.refImage.inputImage.originalImageUrl}&d=240x240`}
                          alt="result image"
                          fill
                          quality={90}
                          style={{
                            objectFit: 'contain',
                            objectPosition: 'center',
                            borderRadius: '8px',
                            outline: '1px solid #ccc',
                          }}
                        />
                      </ImageSpinner>
                    </Box>
                    <Box
                      style={{
                        fontSize: 14,
                        color: '#666',
                        textAlign: 'center',
                      }}
                    >
                      参照画像
                    </Box>
                  </StyledGrid>
                )}
                <GlobalDownloader
                  isGenerated
                  imageResults={taskApiResponse?.result.resultImages || []}
                  setFiles={setFiles}
                  repeatBackgroundImage={
                    taskApiResponse.parameters?.noBackground
                      ? houndstooth.src
                      : ''
                  }
                  hasCheckIcon
                  taskType={taskApiResponse.taskType}
                />
              </StyledFlex>
            </StyledBoxPaddingTop100>
            <StyledFunctionBox>
              <StyledBoxPaddingTop100>
                <SharedDownloadButton
                  bottom={48}
                  handleOpenPopper={handleOpenPopper}
                  checkedCount={checkedCount}
                />
                <ReEditButton
                  handleClick={moveToReEditColorTemperature}
                  checkedCount={checkedCount}
                />
              </StyledBoxPaddingTop100>
            </StyledFunctionBox>
          </FeaturesModalDownloadLayout>
        </StyledDialogContainer>
      </>
    );
  },
);
