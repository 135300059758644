import {
  TableCellProps,
  TableRowProps,
  TableCell as Td,
  TableRow as Tr,
} from '@mui/material';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

type TrProps = {
  status: string;
  isSelected: boolean;
  onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
} & TableRowProps;

export const StyledTr = memo(({ status, isSelected, children, onClick }: TrProps) => {
  return (
    <Tr
      className={`
        ${vanilla.styledColumnVariants[
          status === 'IN_PROGRESS' || status === 'IN_QUEUE'
          ? 'inProgress'
          : status === 'FAILED' || status === 'TIMEOUT'
          ? 'another'
          : 'completed'
        ]} ${isSelected ? vanilla.styledColumnSelected : ''}
      `}
      onClick={onClick}
    >
      {children}
    </Tr>
  );
});

export const StyledTdFile = memo(({ children }: TableCellProps) => {
  return <Td className={vanilla.styledTdFile}>{children}</Td>;
});

export const StyledTdFixed = memo(({ children }: TableCellProps) => {
  return <Td className={vanilla.styledTdFixed}>{children}</Td>;
});
