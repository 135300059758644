import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { useConfirmEraseDataDialog } from '@/features/components/modals/ConfirmEraseDataDialog/hooks/useConfirmEraseDataDialog';
import { useBatchOptionalContext } from '@/features/components/steps/hooks/useBatchOptionalContext';
import { useCallback, useEffect } from 'react';

/**
 * @see バッチ処理のデータがある場合、ページ遷移やリロード前にデータが消失する旨を確認するカスタムフック
 */
export const useConfirmEraseData = () => {
  const {
    featureData,
    activeFeatureName,
    findActiveStep,
    updateFeatureDataArray,
    activateTargetFeature,
    activateTargetStep,
  } = useFeaturesContext({});

  const {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    setTargetFeatureName,
    targetFeatureName,
  } = useConfirmEraseDataDialog();
  const { setIsStopBatchGenerating } = useBatchOptionalContext();

  const handleSubmitEraseDialog = useCallback(() => {
    setIsStopBatchGenerating(true);
    updateFeatureDataArray('batch', []);
    activateTargetFeature(targetFeatureName);
    handleCloseConfirmEraseDataDialog();
    if (targetFeatureName === 'backgroundLora') {
      activateTargetStep('batchUpload');
    }
    setTargetFeatureName('');
  }, [
    setIsStopBatchGenerating,
    updateFeatureDataArray,
    activateTargetFeature,
    targetFeatureName,
    handleCloseConfirmEraseDataDialog,
    setTargetFeatureName,
    activateTargetStep,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      return '';
    };
    if (!featureData) {
      return () => {};
    }
    /* 色温度でポップアップからダウンロードする場合は警告を表示しない */
    // ↑逆になぜ色温度以外では警告を表示している？（どのシーンで警告している？）
    if (
      (activeFeatureName === 'colorTemperature' || activeFeatureName === 'reEditColorTemperature') &&
      findActiveStep()?.name === 'batchDownload'
    ) {
      return () => {};
    }
    if (featureData.batch.length) {
      if (
        featureData.batch[0].refImage.refUrl ||
        featureData.batch[0].originalImages.length
      ) {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }
    }

    return () => {};
  }, [activeFeatureName, featureData, findActiveStep]);

  return {
    isOpenConfirmEraseDataDialog,
    handleCloseConfirmEraseDataDialog,
    targetFeatureName,
    handleSubmitEraseDialog,
  };
};
