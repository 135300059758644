import {
  BackgroundIcon,
  CheckedCircle,
  ColorIcon,
  ColorTemperatureIcon,
  FileIcon,
  FolderIcon,
  GalleryIcon,
  ItemIcon,
  ModelIcon,
  SuccessIcon,
  TaskIcon,
  UserIcon,
  WhiteIcon,
} from '@/components/icons';
import { StyledCircle32 } from '@/features/components/styled/icon';
import { useCallback } from 'react';
import { MdAdd, MdError, MdInfo, MdRemove } from 'react-icons/md';

/**
 * JsDoc
 * @see 指定のアイコンを返すカスタムフック
 * @see サイズ指定は出来ない、呼び出し元でtransform:scale()で調整する
 * @returns {Object}
 */
export const useIcons = () => {
  const returnIcon = useCallback(
    (type: string | null | undefined, color?: string) => {
      switch (type) {
        case 'background':
          return (
            <BackgroundIcon color={color || 'var(--color-primary-main)'} />
          );
        case 'backgroundLora':
          return (
            <BackgroundIcon color={color || 'var(--color-primary-main)'} />
          );
        case 'white':
          return <WhiteIcon color={color || 'var(--color-primary-main)'} />;
        case 'multiWhite':
          return <WhiteIcon color={color || 'var(--color-primary-main)'} />;
        case 'misc':
          return <WhiteIcon color={color || 'var(--color-primary-main)'} />;
        case 'color':
          return <ColorIcon color={color || 'var(--color-primary-main)'} />;
        case 'gallery':
          return <GalleryIcon color={color || 'var(--color-primary-main)'} />;
        case 'item':
          return <ItemIcon color={color || 'var(--color-primary-main)'} />;
        case 'outfit':
          return <ItemIcon color={color || 'var(--color-primary-main)'} />;
        case 'colorTemperature':
          return (
            <ColorTemperatureIcon
              color={color || 'var(--color-primary-main)'}
            />
          );
        case 'model':
          return <ModelIcon color={color || 'var(--color-primary-main)'} />;
        case 'task':
          return <TaskIcon color={color || 'var(--color-primary-main)'} />;
        case 'information':
          return <MdInfo size={32} color={color || 'var(--color-gray-main)'} />;
        case 'single':
          return <FileIcon color={color || 'var(--color-primary-main)'} />;
        case 'batch':
          return <FolderIcon color={color || 'var(--color-primary-main)'} />;
        case 'user':
          return <UserIcon color={color || 'var(--color-primary-main)'} />;
        case 'success':
          return <SuccessIcon />;
        case 'error':
          return <MdError size={24} color="#f00" />;
        case 'checked':
          return <CheckedCircle color={color || 'var(--color-primary-main)'} />;
        case 'remove':
          return (
            <StyledCircle32>
              <MdRemove size={24} />
            </StyledCircle32>
          );
        case 'add':
          return (
            <StyledCircle32>
              <MdAdd size={24} />
            </StyledCircle32>
          );

        default:
          return <MdInfo size={32} color={'var(--color-gray-main)'} />;
      }
    },
    [],
  );

  return { returnIcon };
};
