import { SelectableThumbnail } from '@/components/molecules/SelectableThumbnail/SelectableThumbnail';
import { AccordionWrapper } from '@/features/components/molecules/AccordionWrapper';
import { useImageMasking } from '@/hooks/utils/useImageMasking';
import { useResizeBase64 } from '@/hooks/utils/useResizeBase64';
import { Switch } from '@mui/material';
import Image from 'next/image';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useReEditAtom } from '../../../hooks/useReEditAtom';
import { useTaskData } from '../../../hooks/useTaskData';
import { contentsWrapperStyle, imagesWrapperStyle, refImageWrapper, resultImagesListStyle, switchWrapperStyle } from './AccordionRow.css';
import { Bar } from './Bar';

type Props = {
  handleOpenSetNameDialog: () => void;
};

// MEMO: 再生成としてはAccordionの機能は持っていないので名前を変えた方が良い？（見た目が似ているという意味でいったんそのままにしている）
export const AccordionRow = memo(
  ({
    handleOpenSetNameDialog,
  }: Props): JSX.Element => {
    const { taskData } = useTaskData();
    const {
      maskImagesMap,
      selectedIndex,
      setSelectedIndex,
      targetOriginalImages,
    } = useReEditAtom()

    const [displayImageType, setIsDisplayImageType] = useState<'original' | 'mask'>('mask');

    const maskImage = useCallback((index) => {
      return maskImagesMap?.[index]?.maskOverlayImageBase64;
    }, [maskImagesMap])

    const { getImageMasking } = useImageMasking();
    const { getResizedBase64 } = useResizeBase64();
    const [combinedRefBase64, setCombinedRefBase64] = useState<string | undefined>(undefined);
    useEffect(() => {
      void (async () => {
        if (taskData === undefined) return;

        const combinedBase64 = await getImageMasking({
          mainImageSource: taskData.refImage?.inputImage.originalImageUrl || '',
          maskImageSource: taskData.refImage?.maskImage?.originalImageUrl || '',
        });
        // MEMO: メモリ観点でリサイズしておいた方が良い？（リサイズ処理は0.1秒程度）
        // MEMO: 画面表示時はオリジナル画像を表示するのでいったん裏でリサイズしておく
        const resizedBase64 = await getResizedBase64({
          targetSize: 240,
          width: 240, // TODO: resize処理を確認して最適化する（比率が1:1でなくても良い感じにしてくれている）
          height: 240,
          originalBase64: combinedBase64,
        });
        setCombinedRefBase64(resizedBase64.base64);
      })();
    // TODO: なぜかdisplayTypeを変更するたびにgetImageMaskingやgetResizedBase64が反応して再実行されてしまう。問題は出ないが気持ちが悪いので要調査。
    }, [taskData, getImageMasking, getResizedBase64]);

    const refImageSrc = useMemo(() => {
      switch (displayImageType) {
        case 'original':
          return taskData?.refImage?.inputImage.originalImageUrl;
        case 'mask':
          return combinedRefBase64;
        default:
          throw new Error("Invalid displayImageType");
      }
    }, [taskData, displayImageType, combinedRefBase64]);

    const handleToggleSwitch = useCallback(() => {
      setIsDisplayImageType((prev) => {
        return prev === 'original' ? 'mask' : 'original';
      });
    }, [])

    return (
      <>
        <AccordionWrapper
          isOpenAccordion
        >
          <Bar
            handleOpenSetNameDialog={handleOpenSetNameDialog}
          />
          <div className={contentsWrapperStyle}> {/* AccordionWrapperがchildren[0]を表示する仕組みなのでFragmentタグで囲っている（そもそもchildren[n]みたいなのは親コンポーネントを見ないと分からないのでやめるか対策したい） */}
            <div className={switchWrapperStyle}>
              <div>画像の選択範囲を表示</div>
              <div>
              <Switch
                checked={displayImageType === 'mask'}
                onChange={handleToggleSwitch}
              />
              </div>
            </div>
            <div className={imagesWrapperStyle}>
              <div className={refImageWrapper}>
                {refImageSrc &&
                  <Image
                    src={refImageSrc}
                    width={240}
                    height={240}
                    alt=""
                  />
                }
              </div>
              {targetOriginalImages.length > 0 && (
                <ul className={resultImagesListStyle}>
                  {targetOriginalImages.map((image, index) => (
                    <li key={image.inputImage.id}>
                      <SelectableThumbnail
                        originalImageSrc={image.inputImage.originalImageUrl}
                        maskImageSrc={maskImage(index)}
                        displayImageType={displayImageType}
                        isSelected={selectedIndex === index}
                        onClick={() => {setSelectedIndex(index)}}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </AccordionWrapper>
      </>
    );
  },
);
