import { Dispatch, Fragment, memo, SetStateAction, useCallback } from 'react';
import { StyledNegativePointPin, StyledPositivePointPin } from './styled';

type Props = {
  pointType: string;
  points: Array<[number, number]>;
  samType: 'manual' | 'auto';
  setPositivePoints: Dispatch<SetStateAction<[number, number][]>>;
  setNegativePoints: Dispatch<SetStateAction<[number, number][]>>;
  setIsPreviewCompleted: Dispatch<SetStateAction<boolean>>;
};

/**
 * JSDoc
 * @see セグメンテーションのポイントPinを表示するコンポーネント
 * @see Pinクリック時に配列から該当の座標を削除するハンドラーを持つ
 * @param {Props} { field, pointType, points }
 * @returns {JSX.Element}
 */
export const DisposePointPin = memo(
  ({
    pointType,
    points,
    samType,
    setPositivePoints,
    setNegativePoints,
    setIsPreviewCompleted,
  }: Props): JSX.Element => {
    const handleRemoveTargetPin = useCallback(
      (index: number) => {
        if (pointType === 'positive') {
          setPositivePoints((prev) => prev.filter((_, i) => i !== index));
          // setIsPreviewCompleted(false);
        } else {
          setNegativePoints((prev) => prev.filter((_, i) => i !== index));
          // setIsPreviewCompleted(false);
        }
      },
      [
        pointType,
        setPositivePoints,
        setNegativePoints,
        setIsPreviewCompleted,
      ],
    );

    return (
      <>
        {samType === 'manual' &&
          points.map((point, index) => (
            <Fragment key={`${pointType}-${index}`.toString()}>
              {pointType === 'positive' && (
                <StyledPositivePointPin
                  left={point[0]}
                  top={point[1]}
                  onClick={() => {
                    handleRemoveTargetPin(index);
                  }}
                  data-testid="positive-point-pin"
                />
              )}
              {samType === 'manual' &&
                pointType === 'negative' && (
                  <StyledNegativePointPin
                    left={point[0]}
                    top={point[1]}
                    onClick={() => {
                      handleRemoveTargetPin(index);
                    }}
                    data-testid="negative-point-pin"
                  />
                )}
            </Fragment>
          ))}
      </>
    );
  },
);
