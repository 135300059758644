import {
  axiosDelete,
  axiosGet,
  axiosGetWithZod,
  axiosPost,
  axiosPostWithZod,
} from '@/api/axios';
import {
  Image,
  ImageSchema,
  PageInfo,
  PresetsSchema,
  SegmentationSchema,
  TaskResultImage,
  TaskSchema,
  UserSchema
} from '@/api/schemas';
import { PostTasksV2Param } from '@/types/backendApi';

/**
 * JSDoc
 * @see バックエンドAPIカスタムフック
 * @param {Object} { isInverseResult }
 * @returns {Object}
 */
type Props = { isInverseResult?: boolean };
export const useBackendApi = ({ isInverseResult = false }: Props) => {
  const getTask = (id: string, param: unknown) => {
    const data = axiosGetWithZod(`gen/tasks/${id}`, TaskSchema, param);

    return data;
  };

  const getTaskImagesWithZip = (id: string, param: unknown) => {
    const data = axiosGet(`gen/tasks/${id}/images/download`, param);

    return data as unknown as TaskResultImage;
  };

  const getImages = (param: unknown) => {
    const data: Promise<GetImagesResponse> = axiosGet('images', param);

    return data;
  };

  const getTaskImageWithoutZip = (
    id: string,
    imageId: string,
    param: unknown,
  ) => {
    const data = axiosGet(`gen/tasks/${id}/images/${imageId}/download`, param);

    return data as unknown as TaskResultImage;
  };

  const postSegmentationBackground = (base64: string) => {
    const data = axiosPostWithZod(
      'gen/segmentation/background',
      SegmentationSchema,
      {
        image_base64: base64,
        inverse_result: isInverseResult,
      },
    );

    return data;
  };

  const postSegmentationAnything = (
    base64: string,
    apiPositivePoints: [number, number][], // 整数のペアの配列
    apiNegativePoints: [number, number][], // 整数のペアの配列
  ) => {
    const data = axiosPostWithZod(
      'gen/segmentation/anything',
      SegmentationSchema,
      {
        image_base64: base64,
        positive_points: apiPositivePoints,
        negative_points: apiNegativePoints,
        inverse_result: isInverseResult,
      },
    );

    return data;
  };

  const getUser = (id: string) => {
    const data = axiosGetWithZod(`users/${id}`, UserSchema, {});

    return data;
  };

  const getMe = () => {
    return axiosGet(`users/by/auth0`).then((data) => {
      return axiosGet(`users/${data.id}`);
    });
  };

  const deleteUser = (id: string) => {
    const data = axiosDelete(`users/${id}`);

    return data;
  };

  const postImages = (param: unknown) => {
    const data = axiosPostWithZod('images', ImageSchema, param);

    return data;
  };

  const postTasksV2 = (param: PostTasksV2Param) => {
    const data = axiosPost('gen/tasksv2', param);

    return data;
  };

  const getPresets = () => {
    const data = axiosGetWithZod(`gen/presets`, PresetsSchema, { limit: 10 });

    return data;
  };

  return {
    getTaskImagesWithZip,
    getTaskImageWithoutZip,
    getTask,
    getImages,
    getUser,
    getMe,
    deleteUser,
    postSegmentationBackground,
    postSegmentationAnything,
    postImages,
    postTasksV2,
    getPresets,
  };
};

// TODO: zod(schema)を使う形に修正する
export type GetImagesResponse = {
  data: Image[],
  pageInfo: PageInfo,
}
