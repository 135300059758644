import { useBackendApi } from '@/api';
import { Task } from '@/api/schemas';
import { useEffect, useState } from 'react';

type Props = {
  taskId: string;
};

/**
 * @see ダイアログを開いた際にタスクを取得する
 */
export const useGetTaskApi = ({ taskId }: Props) => {
  const [taskApiResponse, setTaskApiResponse] = useState<Task | undefined>(
    undefined,
  );

  const { getTask } = useBackendApi({});

  useEffect(() => {
    if (!taskId) return;
    const fetchTask = async () => {
      try {
        const response = await getTask(taskId, {});
        setTaskApiResponse(response);
      } catch (error) {
        console.error(error);
      }
    };

    void fetchTask();

    // 無限ループ回避
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  return { taskApiResponse, setTaskApiResponse };
};
