import { Dialog } from '@/components/molecules/Dialog';
import { PopupDownloadFormat } from '@/features/components/modeless/PopupDownloadFormat';
import { memo } from 'react';
import { BatchDownloadContent } from './BatchDownloadContent';
import { ColorTemperatureContent } from './ColorTemperatureContent';
import { SingleDownloadContent } from './SingleDownloadContent';
import { useGetTaskApi } from './hooks/useGetTaskApi';
import { useHandlers } from './hooks/useHandlers';
import { useInitializeDownloadDialog } from './hooks/useInitializeDownloadDialog';

type Props = {
  taskId: string;
  isOpenDownloadDialog: boolean;
  handleCloseDownloadDialog: () => void;
  targetModeEn?: string;
  targetModeJp?: string;
};
export const DownloadDialog = memo(
  ({
    taskId,
    isOpenDownloadDialog,
    handleCloseDownloadDialog,
    targetModeEn,
    targetModeJp,
  }: Props): JSX.Element => {
    const { taskApiResponse, setTaskApiResponse } = useGetTaskApi({ taskId });

    const {
      // handleCloseDialogWithPopper,
      handleOpenPopper,
      handleClosePopper,
      isOpenPopper,
      anchorElement,
      checkedCount,
    } = useHandlers();

    const { hasPsd, isNoBackground } = useInitializeDownloadDialog({
      taskApiResponse,
      targetModeEn,
    });

    return (
      <>
        {taskId && taskApiResponse && targetModeJp && (
          <Dialog
            isOpenDialog={isOpenDownloadDialog}
            onClose={handleCloseDownloadDialog} // クローズさせない // <- コメントの意味が読み取れなかった。要整理
            maxWidth="xl"
            dialogTitle=""
            hasCloseIcon
          >
            {targetModeEn === 'colorTemperature' ? (
              <ColorTemperatureContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
              />
            ) : targetModeEn === 'backgroundLora' ||
              targetModeEn === 'multiWhite' ? (
              <BatchDownloadContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
              />
            ) : (
              <SingleDownloadContent
                taskApiResponse={taskApiResponse}
                handleOpenPopper={handleOpenPopper}
                checkedCount={checkedCount}
              />
            )}
          </Dialog>
        )}
        <PopupDownloadFormat
          popperTitle="出力形式の選択"
          popperPlacement="bottom"
          anchorElement={anchorElement}
          isOpenPopper={isOpenPopper}
          handleClosePopper={handleClosePopper}
          taskApiResponse={taskApiResponse}
          /* モーダルにポップアップを表示すると影が分かりづらいため濃くする */
          boxShadow="0px 0px 20px 0px rgba(0,0,0,0.5)"
          isNoBackground={isNoBackground}
          hasPsd={hasPsd}
        />
      </>
    );
  },
);
