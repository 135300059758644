import { useBackendApi } from '@/api';
import { Task, TaskResultImage } from '@/api/schemas';
import { useCheckArray } from '@/hooks/global/useCheckArray';
import { useError } from '@/hooks/global/useError';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  taskApiResponse: Task | undefined;
};
/**
 * @see ダウンロードを行うカスタムフック
 * @see 一枚生成から作られたためロジックが複雑 todo もう少しシンプルにしたい
 */
export const useDownload = ({ taskApiResponse }: Props) => {
  const { logger } = useError();
  const [apiResponse, setApiResponse] = useState<TaskResultImage | undefined>(
    undefined,
  );
  const [imageIdsArray, setImageIdsArray] = useState<string[] | undefined>([]);
  const [downloadingName, setDownloadingName] = useState('');
  const [isDownloaded, setIsDownloaded] = useState(false);

  const { getTaskImagesWithZip, getTaskImageWithoutZip } = useBackendApi({});
  const {
    checkArray,
    checkedCount,
    handleFindCheckedOne,
    setCheckArray,
    downloadedArray,
    setDownloadedArray,
  } = useCheckArray();
  const [files, setFiles] = useState<string[]>([]);

  const handleUpdateCheckArrayAndDownloadedArray = useCallback(() => {
    const newCheckArray = checkArray.map(() => false);
    const newDownloadedArray = downloadedArray.map((d, i) =>
      checkArray[i] ? true : d,
    );
    setDownloadedArray(newDownloadedArray);
    setCheckArray(newCheckArray);
  }, [checkArray, downloadedArray, setCheckArray, setDownloadedArray]);

  /**
   * @see チェックされた画像をダウンロードする
   * @see 一枚の場合はZipで固めないでダウンロードする
   */
  const handleDownloadWithApi = useCallback(
    async (format: string) => {
      if (!taskApiResponse) return;
      if (!imageIdsArray?.length) return;
      handleUpdateCheckArrayAndDownloadedArray();
      setDownloadingName(format);
      try {
        if (imageIdsArray.length === 1) {
          const param = {
            format,
          };

          // eslint-disable-next-line @typescript-eslint/await-thenable
          const tmp = await getTaskImageWithoutZip(
            taskApiResponse.id,
            imageIdsArray[0],
            param,
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          fetch(tmp.url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;

              const randomString = uuidv4()
                .replace(/-/g, '')
                .toUpperCase()
                .slice(0, 24);
              const fileName = taskApiResponse.originalImages?.[0].inputImage.fileName || ''
              const originalFileName = fileName.split('.')[0];
              a.download = `${originalFileName}_${randomString}.${format}`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            });
        } else {
          const param = {
            image_ids: imageIdsArray,
            format,
          };

          setApiResponse(
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await getTaskImagesWithZip(taskApiResponse.id, param),
          );
        }
      } catch (error) {
        console.error(error);
        logger({ error });
        setDownloadingName('');
      }
      setDownloadingName('');
      setIsDownloaded(true);
    },
    [
      getTaskImageWithoutZip,
      getTaskImagesWithZip,
      imageIdsArray,
      logger,
      taskApiResponse,
    ],
  );

  useEffect(() => {
    if (!apiResponse) return;
    const link = document.createElement('a');
    link.href = apiResponse.url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    setDownloadingName('');
  }, [apiResponse]);

  useEffect(() => {
    if (!taskApiResponse) return;
    const newImageIdsArray: string[] = checkArray
      .map((d, i) => d && taskApiResponse.result.resultImages[i]?.id)
      .filter((imageId): imageId is string => Boolean(imageId));
    setImageIdsArray(newImageIdsArray);
  }, [checkArray, taskApiResponse]);

  return {
    downloadingName,
    isDownloaded,
    setIsDownloaded,
    handleDownloadWithApi,
    checkArray,
    checkedCount,
    handleFindCheckedOne,
    files,
    setFiles,
  };
};
