import { Dialog } from '@/components/molecules/Dialog';
import {
  StyledFlexCenter,
  StyledGrid,
} from '@/components/styled';
import { LinearProgress } from '@mui/material';
import { memo, useState } from 'react';
import { StyledConfirmBody, StyledConfirmDialogContainer, StyledProgressText, StyledTitle } from './styled';

type Props = {
  title?: string;
  isOpen: boolean;
  loadingProgressPercent: number | null;
};

export const BatchUploadingDialog = memo(
  ({
    title = 'アップロード中です...',
    isOpen,
    loadingProgressPercent = 0,
  }: Props): JSX.Element => {
    const [isConfirmCancel] = useState(false);

    return (
      <Dialog
        isOpenDialog={isOpen}
        onClose={() => {}}
        maxWidth="sm"
        dialogTitle=""
        hasCloseIcon={false}
      >
        {!isConfirmCancel && (
          <StyledConfirmDialogContainer>
            <StyledConfirmBody>
              <StyledGrid>
                <StyledFlexCenter style={{marginBottom: '24px'}}>
                  {loadingProgressPercent ? (
                    <LinearProgress
                      sx={{ width: 200, height: 4 }}
                      variant="determinate"
                      value={loadingProgressPercent}
                    />
                  ) : (
                    <LinearProgress sx={{ width: 200, height: 4 }} />
                  )}
                </StyledFlexCenter>
                <StyledTitle>{title}</StyledTitle>
                <StyledProgressText>{Math.round(loadingProgressPercent ?? 0)}%&nbsp;完了...</StyledProgressText>
              </StyledGrid>
            </StyledConfirmBody>
          </StyledConfirmDialogContainer>
        )}
      </Dialog>
    );
  },
);
