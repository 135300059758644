import { Task } from '@/api/schemas';
import { Box } from '@/components/atoms/Box';
import { StyledFont, StyledGridGap5 } from '@/components/styled';
import { TASK_TYPE_TO_EN_MODE_FROM_BACKEND } from '@/constants';
import {
  StyledCircle,
  StyledCircleImage,
} from '@/features/Gallery/uniques/styled';
import { getModeEn } from '@/utils/task';
import hexToRgba from 'hex-to-rgba';
import { memo, useMemo } from 'react';
import { StyedTaskContentContainer } from './styled';

type Props = {
  data: Task;
};
/**
 * JSDoc
 * @see タスクの指示内容を表示するコンポーネント
 * @see ギャラリーやモーダルダウンロードにて使用する汎用コンポーネント
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const TaskCellContent = memo(({ data }: Props): JSX.Element => {
  const modeEn = getModeEn(
    data.taskType as keyof typeof TASK_TYPE_TO_EN_MODE_FROM_BACKEND,
  );
  const isDisplayDetail = useMemo(() => {
    return modeEn !== 'white' && modeEn !== 'multiWhite';
  }, [modeEn]);

  const detailContent = useMemo(() => {
    if (data.parameters?.color) {
      return 'カラー指定';
    }
    if (data.parameters?.noBackground) {
      return '透過';
    }
    if (data.parameters?.presetId) {
      return 'プリセット指定';
    }

    return '画像指定';
  }, [data.parameters]);

  return (
    <Box>
      <StyedTaskContentContainer>
        {isDisplayDetail && (
          <Box>
            {data.parameters?.color && (
              <StyledCircle
                sx={{
                  backgroundColor: data.parameters.color,
                }}
              />
            )}
            {data.refImage?.inputImage.originalImageUrl &&
              modeEn !== 'white' &&
              !data.parameters?.color && (
                <>
                  <StyledCircleImage
                    src={`${data.refImage.inputImage.originalImageUrl}&d=56x56`}
                    width={56}
                    height={56}
                    alt={`${data.refImage.inputImage.originalImageUrl}`}
                  />
                </>
              )}
          </Box>
        )}
        <StyledGridGap5
          sx={{
            whiteSpace: 'nowrap',
            fontColor:
              data.result.status === 'IN_QUEUE' ||
              data.result.status === 'FAILED'
                ? hexToRgba('#000', 0.6)
                : 'var(--color-gray-main)',
          }}
        >
          {isDisplayDetail && (
            <Box>
              <StyledFont>{detailContent}</StyledFont>
            </Box>
          )}
          <Box>
            <StyledFont>生成数:{data.result.resultImages.length}</StyledFont>
          </Box>
        </StyledGridGap5>
      </StyedTaskContentContainer>
    </Box>
  );
});
